import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1042.000000 1042.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1042.000000) scale(0.100000,-0.100000)" >
<path d="M4825 10150 c-1026 -90 -1924 -448 -2721 -1086 -475 -380 -889 -867
-1197 -1405 -59 -103 -207 -392 -207 -403 0 -3 -13 -33 -29 -68 -81 -178 -192
-495 -250 -718 -158 -599 -200 -1253 -121 -1879 79 -626 282 -1241 588 -1781
115 -204 312 -502 413 -625 9 -11 38 -47 65 -80 219 -276 574 -617 879 -846
884 -664 1998 -1020 3087 -985 1038 33 1977 355 2798 960 230 169 337 262 570
496 498 498 839 1014 1102 1665 171 424 277 868 330 1375 17 167 17 735 0 900
-65 613 -207 1133 -453 1650 -484 1023 -1289 1840 -2304 2340 -514 252 -1032
402 -1650 476 -152 19 -743 27 -900 14z m755 -231 c1246 -92 2422 -699 3241
-1674 597 -711 960 -1555 1075 -2505 22 -183 30 -666 15 -868 -36 -467 -135
-912 -296 -1332 -189 -494 -451 -939 -796 -1355 -112 -135 -447 -469 -589
-586 -670 -556 -1440 -906 -2293 -1043 -400 -65 -886 -72 -1313 -21 -796 96
-1586 414 -2229 896 -207 154 -317 250 -516 449 -282 280 -477 524 -675 842
-414 664 -645 1378 -705 2170 -15 195 -7 640 15 831 64 571 220 1096 476 1609
520 1041 1436 1873 2520 2288 667 255 1342 353 2070 299z"/>
<path d="M7213 8881 c-39 -24 -53 -49 -53 -93 0 -75 47 -103 186 -112 527 -33
970 -445 1043 -970 6 -43 11 -101 11 -129 0 -87 44 -137 121 -137 37 0 85 44
95 86 10 40 -13 257 -37 351 -43 166 -128 343 -227 478 -115 154 -325 327
-502 412 -146 71 -338 120 -503 129 -91 5 -105 3 -134 -15z"/>
<path d="M2513 8589 c-74 -28 -144 -95 -176 -167 l-27 -60 2 -2668 c3 -2630 3
-2670 23 -2709 31 -65 113 -139 178 -164 l58 -21 2652 2 2652 3 55 26 c104 50
163 133 180 251 11 77 13 4238 2 4238 -4 0 -43 -15 -87 -34 l-80 -33 -5 -2101
-5 -2100 -25 -27 c-54 -59 134 -55 -2700 -55 -2834 0 -2646 -4 -2700 55 l-25
27 -3 2642 -2 2643 23 34 c12 19 38 42 57 51 33 17 161 18 2236 18 l2201 0 36
53 c21 30 48 64 62 77 14 13 25 27 25 32 0 4 -1025 8 -2277 7 -2253 0 -2279 0
-2330 -20z"/>
<path d="M7220 8494 c-25 -8 -60 -68 -60 -103 0 -42 44 -88 91 -94 19 -3 70
-10 115 -16 169 -21 303 -87 424 -210 137 -138 220 -325 220 -493 0 -91 39
-138 114 -138 38 0 82 38 92 77 8 30 -3 146 -21 233 -86 408 -459 726 -875
745 -47 2 -92 2 -100 -1z"/>
<path d="M7214 8110 c-59 -23 -73 -112 -26 -159 23 -23 39 -29 98 -35 132 -13
251 -92 308 -204 15 -29 32 -89 40 -139 12 -73 18 -91 41 -110 39 -34 98 -32
136 6 25 26 29 37 29 88 0 246 -189 481 -440 548 -60 16 -153 19 -186 5z"/>
<path d="M3740 7924 c-14 -2 -65 -11 -115 -20 -368 -62 -664 -274 -769 -552
-46 -120 -59 -198 -60 -337 0 -149 14 -236 57 -350 89 -238 268 -400 562 -510
116 -43 166 -57 495 -131 135 -31 280 -69 322 -86 149 -57 240 -138 270 -242
20 -70 15 -222 -11 -288 -40 -103 -132 -183 -258 -224 -74 -25 -334 -30 -451
-10 -236 41 -470 182 -622 376 -52 65 -69 80 -91 80 -17 0 -32 -8 -42 -22 -8
-13 -85 -108 -171 -213 -86 -104 -156 -193 -156 -196 0 -19 77 -116 150 -190
182 -183 434 -308 745 -370 108 -22 146 -24 385 -24 210 0 284 4 357 17 439
83 716 332 805 721 29 125 31 355 5 477 -60 281 -219 477 -488 605 -138 65
-253 99 -549 160 -439 92 -509 116 -601 205 -67 65 -92 138 -87 250 5 99 26
149 89 210 130 126 442 165 739 93 128 -31 299 -125 385 -212 26 -26 49 -40
66 -41 28 0 17 -14 240 290 117 160 113 139 43 205 -163 155 -387 259 -664
310 -91 17 -515 31 -580 19z"/>
<path d="M7225 7679 c-60 -29 -85 -131 -44 -183 66 -84 184 -68 218 31 13 35
13 46 0 79 -26 68 -111 104 -174 73z"/>
<path d="M5204 6002 c3 -9 10 -39 16 -67 6 -27 27 -115 46 -195 19 -80 44
-181 54 -225 51 -213 228 -958 280 -1178 32 -137 60 -251 63 -253 2 -3 132 -8
289 -11 l285 -6 35 129 c20 71 39 145 43 164 4 19 10 40 13 45 4 6 7 15 8 20
2 9 40 156 120 455 18 69 44 163 57 210 l24 85 17 -60 c9 -33 24 -80 32 -105
8 -25 64 -218 124 -430 59 -212 115 -410 124 -441 l17 -56 136 -6 c202 -10
431 -8 438 3 8 13 37 127 69 275 15 66 31 136 37 155 5 19 27 112 49 205 22
94 67 287 101 430 33 143 67 289 75 325 7 35 18 78 23 95 16 52 91 413 91 438
0 16 -20 17 -264 17 l-264 0 -21 -92 c-21 -91 -101 -479 -122 -588 -22 -117
-79 -362 -88 -377 -6 -12 -12 -3 -21 35 -6 28 -8 56 -4 63 4 7 3 9 -3 6 -6 -4
-27 54 -53 141 -23 81 -51 174 -61 207 -39 132 -101 346 -103 358 -1 7 -7 21
-13 32 -6 11 -23 64 -38 117 l-27 97 -242 1 -243 1 -30 -108 c-73 -255 -154
-541 -203 -720 -52 -190 -90 -277 -90 -208 0 12 -9 57 -20 101 -11 43 -49 218
-85 388 -36 170 -70 316 -74 325 -4 9 -16 54 -25 101 -10 47 -20 93 -23 103
-4 16 -24 17 -279 17 -253 -1 -275 -2 -270 -18z m460 -172 c8 -56 88 -436 151
-725 61 -276 95 -435 95 -440 0 -3 33 -5 74 -5 l73 0 13 63 c6 34 18 82 25
107 7 25 27 95 44 155 16 61 73 261 125 445 52 184 102 363 111 398 l18 62
152 0 152 0 11 -42 c33 -135 45 -181 67 -253 13 -44 59 -199 101 -345 42 -146
97 -337 123 -425 l46 -160 72 -3 72 -3 6 48 c4 26 23 122 41 213 19 91 71 338
115 550 44 212 82 393 85 403 5 15 21 17 145 17 l141 0 -7 -32 c-10 -52 -16
-68 -23 -68 -4 0 -8 -17 -10 -38 -1 -20 -6 -48 -12 -62 -5 -14 -28 -104 -50
-200 -52 -229 -161 -693 -220 -940 -11 -47 -25 -110 -31 -140 -11 -55 -46
-191 -51 -196 -2 -1 -86 -4 -188 -5 l-185 -3 -13 39 c-7 21 -21 70 -32 109
-10 39 -53 195 -95 346 -110 392 -142 513 -143 530 -1 8 -13 59 -28 113 l-27
97 -64 0 c-34 0 -66 0 -70 1 -7 1 -21 -46 -83 -281 -11 -41 -42 -156 -69 -255
-86 -312 -143 -529 -153 -570 -5 -22 -11 -47 -15 -55 -3 -8 -8 -24 -10 -35 -2
-11 -7 -23 -11 -26 -4 -4 -89 -9 -190 -11 l-183 -3 -10 38 c-5 21 -46 189 -90
375 -43 185 -86 364 -94 397 -38 160 -86 363 -114 480 -17 72 -44 187 -61 256
-16 70 -30 129 -30 133 0 4 66 5 148 4 l147 -3 9 -55z"/>
<path d="M6990 5395 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M3927 2333 c-13 -3 -15 -10 -10 -31 4 -15 7 -129 7 -254 l1 -227 48
-22 c62 -29 158 -31 204 -4 142 84 111 346 -41 345 -22 -1 -52 -7 -66 -15 -14
-8 -29 -14 -33 -15 -5 0 -7 45 -5 101 2 90 0 103 -17 115 -18 14 -52 16 -88 7z
m220 -293 c15 -30 17 -118 3 -155 -12 -31 -48 -46 -87 -36 l-28 7 1 94 c2 79
5 96 20 107 29 21 76 12 91 -17z"/>
<path d="M5290 2334 c-13 -4 -15 -42 -13 -271 1 -209 4 -268 15 -274 7 -5 31
-9 53 -9 l40 0 1 268 c0 230 -2 269 -15 279 -16 12 -51 15 -81 7z"/>
<path d="M5470 2334 c-13 -4 -15 -41 -13 -265 1 -182 6 -265 14 -275 6 -8 29
-14 53 -14 l41 0 1 268 c0 230 -2 269 -15 279 -16 12 -51 15 -81 7z"/>
<path d="M3240 2280 c-62 -8 -100 -29 -127 -69 -27 -40 -29 -89 -7 -133 23
-45 50 -64 121 -88 61 -21 103 -56 103 -86 0 -8 -10 -24 -21 -35 -18 -18 -29
-21 -71 -16 -31 4 -63 16 -82 31 -36 27 -61 21 -75 -17 -20 -53 43 -89 154
-89 82 0 118 12 162 54 58 56 53 155 -10 202 -18 12 -60 33 -95 46 -84 31
-115 76 -76 114 23 24 76 19 120 -10 36 -25 40 -25 64 -11 31 19 37 57 13 80
-23 20 -113 34 -173 27z"/>
<path d="M6050 2177 c0 -36 -3 -43 -21 -44 -28 -2 -49 -21 -49 -44 0 -13 9
-19 38 -21 l37 -3 2 -99 c1 -55 7 -111 13 -126 25 -61 156 -89 202 -42 18 18
21 26 11 40 -11 17 -16 18 -67 6 -46 -11 -56 13 -56 128 l0 98 53 0 c58 0 77
12 77 47 0 22 -4 23 -65 23 -64 0 -65 1 -59 24 8 35 -19 56 -72 56 l-44 0 0
-43z"/>
<path d="M7257 2130 c-25 -20 -28 -20 -56 -5 -42 21 -126 19 -176 -5 -49 -24
-75 -65 -75 -120 0 -49 13 -77 47 -106 l26 -22 -28 -36 c-20 -26 -26 -44 -22
-63 3 -18 -2 -36 -16 -55 -30 -40 -15 -76 43 -106 39 -20 55 -23 115 -18 138
10 205 51 205 127 0 67 -40 89 -161 89 -78 0 -79 0 -79 25 0 23 4 25 48 25
100 1 168 71 157 162 -5 47 -5 47 26 50 39 4 59 30 43 56 -16 25 -65 27 -97 2z
m-93 -82 c18 -26 21 -71 6 -99 -13 -24 -72 -26 -94 -3 -19 18 -22 94 -4 112
21 21 75 15 92 -10z m48 -326 c14 -14 -2 -43 -29 -53 -32 -12 -90 -11 -114 1
-21 12 -26 56 -6 63 20 8 139 -1 149 -11z"/>
<path d="M3513 2133 c-17 -6 -18 -271 -2 -306 24 -54 109 -67 175 -28 l37 22
14 -21 c12 -17 23 -20 56 -18 l42 3 0 164 c0 159 -1 164 -22 179 -16 10 -34
12 -58 8 l-36 -7 2 -124 c0 -68 -2 -128 -5 -133 -3 -6 -20 -15 -36 -21 -26 -9
-34 -7 -50 9 -18 18 -20 34 -20 140 0 108 -2 120 -19 130 -20 10 -57 12 -78 3z"/>
<path d="M4307 2126 c-16 -13 -16 -16 7 -67 24 -55 57 -159 76 -236 10 -41 12
-43 49 -43 50 0 83 27 117 95 14 30 27 55 29 55 1 0 10 -34 20 -75 l17 -75 38
0 c22 0 48 8 63 20 35 28 82 127 107 223 25 99 18 117 -44 117 l-43 0 -7 -62
c-8 -76 -36 -179 -46 -173 -5 3 -12 20 -16 38 -3 17 -17 55 -29 82 -12 28 -20
55 -18 62 3 7 -1 21 -7 32 -8 16 -21 21 -54 21 l-43 0 -7 -62 c-8 -76 -36
-182 -46 -171 -4 4 -17 48 -30 96 -13 49 -32 99 -42 113 -22 27 -62 31 -91 10z"/>
<path d="M4945 2126 c-45 -20 -60 -42 -47 -70 17 -39 46 -41 80 -6 17 16 39
30 51 30 28 0 51 -27 51 -60 0 -26 -3 -28 -57 -35 -76 -9 -129 -39 -137 -76
-20 -103 67 -164 162 -115 29 15 34 15 48 1 9 -9 33 -15 58 -15 l42 0 -4 138
c-5 151 -14 182 -62 207 -36 19 -143 19 -185 1z m135 -226 c0 -34 -4 -42 -26
-52 -18 -9 -30 -9 -42 -1 -35 21 -23 65 23 83 38 16 45 12 45 -30z"/>
<path d="M5732 2123 c-68 -33 -108 -117 -96 -201 14 -93 83 -146 191 -145 73
1 113 21 113 58 0 34 -14 39 -65 20 -66 -26 -125 -3 -125 49 0 21 5 23 61 24
77 2 119 25 138 75 29 77 -31 137 -136 137 -26 0 -63 -8 -81 -17z m108 -89 c0
-37 -20 -54 -64 -54 -20 0 -26 5 -26 21 0 30 18 59 43 68 33 13 47 2 47 -35z"/>
<path d="M6553 2134 c-10 -4 -13 -48 -13 -169 0 -185 0 -185 79 -185 l40 0 -2
133 -2 132 35 17 c29 15 37 16 55 4 18 -12 20 -26 23 -137 1 -72 7 -128 14
-136 7 -9 27 -13 56 -11 l44 3 -1 133 c-2 149 -7 170 -48 202 -23 18 -37 21
-80 18 -33 -3 -61 -12 -74 -24 -20 -18 -21 -18 -34 -1 -13 18 -70 31 -92 21z"/>
<path d="M6352 1894 c-15 -10 -22 -25 -22 -47 1 -44 22 -68 61 -69 61 -2 91
70 47 112 -26 24 -56 26 -86 4z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
